<template>
    <div class="d-flex flex-column p-2 w-100 h-100">
        <div v-if="!hideHeader">
            <h5 class="d-inline-flex">{{$t('Filters')}}</h5>
            <span v-if="filterObject.fieldFilters.length > 0 && filterObject.activeFilter">: {{filterObject.activeFilter.FilterName}}</span>
        </div>

        <BodyWrapper is="div" :disabled="!responsiveLayout" class="row">
            <div class="mb-1 filter-items" :class="{'col-12 col-sm-6 col-md-4': responsiveLayout}" v-for="(col,index) in filterObject.fieldFilters" :key="index+col">
                <FieldFilter
                    :filterObject="filterObject"
                    inputClassName="form-control form-control-sm rounded-0"
                    :columnName="col">
                </FieldFilter>
            </div>
        </BodyWrapper>
    
        <div v-if="filterObject.fieldFilters.length == 0" class="text-muted">
            {{$t('No filter items defined')}}
        </div>

        <div>
            <FieldFilterChooser :filterObject="filterObject ?? dataObject.filterObject" />
        
            <button v-if="filterObject.fieldFilters.length > 0 && filterObject.activeFilter"
                class="btn btn-sm btn-link" 
                :title="$t('Clear applied saved filter')" 
                @click="() => filterObject.clear()">
                    {{$t('Clear')}}
            </button>
        </div>

        <SaveFilter :dataObject="dataObject" v-if="session.personId && dataObject"></SaveFilter>
    

        <template v-if="dataObject && session.personId && !hideSavedFilters">
            <SavedFiltersList :filterObject="dataObject.filterObject" />
           <!-- <h5 class="mt-3 pt-2 mb-1 border-top">{{$t('My Filters')}}</h5>
            <Overlay v-if="dataObject.filterObject.filtersListDO.state.isLoading"/>
            <div class="flex flex-column" style="overflow-y:auto">
                <FiltersList :filterObject="dataObject.filterObject"></FiltersList>
                <h5 class="mt-1 pt-2 mb-0" v-if="dataObject.filterObject.filtersListDO.data.filter(x=>!x.mine)">{{$t('Shared Filters')}} </h5>
                    <div class="form-check form-switch" v-if="dataObject.filterObject.filtersListDO.data.filter(x=>!x.mine && x.Hidden)">
                        <input class="form-check-input" type="checkbox" id="_include_hidden" v-model="includeHidden">
                        <label class="form-check-label" for="_include_hidden">{{$t('Include hidden')}}</label>
                    </div>
            
                <FiltersList :filterObject="dataObject.filterObject" sharedOnly v-if="dataObject.filterObject.filtersListDO.data.filter(x=>!x.mine)" :includeHidden = "includeHidden"></FiltersList>
             </div>-->
        </template>
    </div>
</template>

<script setup lang="ts">
    import { ref } from "vue";
    import { getUserSession } from 'o365.modules.configs.ts';
    import SaveFilter from 'o365.vue.components.SaveFilter.vue';
    import FieldFilter from 'o365.vue.components.FieldFilter.vue';
    import SavedFiltersList from 'o365.vue.components.Filters.SavedList.vue';
    import FieldFilterChooser from 'o365.vue.components.FieldFilterChooser.vue';
    import { BodyWrapper } from 'o365.vue.components.jsx';

    const props = defineProps<{
        dataObject?:any;
        filterObject?: any;
        hideSavedFilters?: boolean;
        hideHeader?: boolean;
        responsiveLayout?: boolean
    }>();
    const includeHidden = ref(false);
    const session = getUserSession();
</script>
